import CityInfo, {CityInfoData} from "@/types/clients/esquire/data/offer/city-info";
import _ from "lodash";
import Room, {RoomData} from "@/types/clients/esquire/data/offer/room";
import Overview, {OverviewData} from "@/types/clients/esquire/data/offer/overview";
import ClientOffer, {ClientOfferData} from "@/types/clients/esquire/data/offer/client-offer";
import GeneralInfo, {GeneralInfoData} from "@/types/clients/esquire/data/offer/general-info";

export type OfferData = {
  client_offer: ClientOfferData;
  city_info: CityInfoData;
  flat_summary: Record<string, { rooms: RoomData[] }>;
  overview: OverviewData;
  general_info: GeneralInfoData;
};

export default class Offer {
  public clientOffer: ClientOffer;
  public cityInfos: CityInfo[];
  public rooms: Room[];
  public overview: Overview;
  public generalInfo: GeneralInfo;

  constructor(data: OfferData) {
    this.clientOffer = new ClientOffer(data.client_offer);
    this.cityInfos = _.map(data.city_info, (cityInfo: CityInfoData) => new CityInfo(cityInfo));
    this.rooms = _.flatMap(data.flat_summary ?? {}, (flatSummary: Record<string, RoomData[]>) =>
      flatSummary.rooms ? flatSummary.rooms.map((room: RoomData) => new Room(room)) : []
    );
    this.overview = new Overview(data.overview);
    this.generalInfo = new GeneralInfo(data.general_info);
  }

  toJson(): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (this.clientOffer !== null) json.client_offer = this.clientOffer.toJson();
    if (this.cityInfos !== null) json.city_info = this.cityInfos.map((cityInfo :CityInfo) => cityInfo.toJson());
    if (this.rooms && this.rooms.length > 0) {
      const flatSummary: Record<string, { rooms: RoomData[] }> = {};

      this.rooms.forEach((room: Room) => {
        const roomJson = room.toJson() as RoomData;
        const flatType = room.flatType;

        if (!flatSummary[flatType]) {
          flatSummary[flatType] = { rooms: [] };
        }
        flatSummary[flatType].rooms.push(roomJson);
      });

      json.flat_summary = flatSummary;
    }
    if (this.overview !== null) json.overview = this.overview.toJson();
    if (this.generalInfo !== null) json.general_info = this.generalInfo.toJson();

    return json;
  }

  public getFlatTypes(): string[] {
    return this.rooms.map((room: Room) => room.flatType);
  }


}
