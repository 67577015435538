import {EsquireClient} from "@/types/clients/esquire";
import Offer, {OfferData} from "@/types/clients/esquire/data/offer/offer";

export default class GetOffers {
  constructor(private client: EsquireClient) {}

  public async send(): Promise<Offer[]> {
    const url = await this.client.fetchUrlFromLocalStorage("get-offers");

    const response = await this.client.fetch(
      () => new URL(url), {
        method: "GET"
      }
    );

    if (!response.ok) {
      await this.client.logOutIfForbidden(response.status);
      throw new Error("Failed to fetch offers");
    }

    const result =  await response.json();
    return result.map((offerData: OfferData) => new Offer(offerData));


  }
}
