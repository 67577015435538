export interface ICrmRepresentative {
  phone_number?: string | null;
  gender?: string | null;
  email?: string | null;
  last_name?: string | null;
  first_name?: string | null;
  address?: string | null;
  zipcode?: string | null;
  city?: string | null;
  country?: string | null;
  language?: string | null;
}

export default class CrmRepresentative {
  public phoneNumber: string | null;
  public gender: string | null;
  public email: string | null;
  public lastName: string | null;
  public firstName: string | null;
  public address: string | null;
  public zipcode: string | null;
  public city: string | null;
  public country: string | null;
  public language: string | null;

  constructor(data: ICrmRepresentative) {
    this.phoneNumber = data.phone_number ?? "";
    this.gender = data.gender ?? "";
    this.email = data.email ?? "";
    this.lastName = data.last_name ?? "";
    this.firstName = data.first_name ?? "";
    this.address = data.address ?? "";
    this.zipcode = data.zipcode ?? "";
    this.city = data.city ?? "";
    this.country = data.country ?? "";
    this.language = data.language ?? "en";
  }

  public static baseModel(): CrmRepresentative {
    return new CrmRepresentative({
      phone_number: "",
      gender: "m",
      email: "",
      last_name: "",
      first_name: "",
      address: "",
      zipcode: "",
      city: "",
      country: "",
      language: "en"
    });
  }

  toJson(): Record<string, unknown> {
    return {
      phone_number: this.phoneNumber,
      gender: this.gender,
      email: this.email,
      last_name: this.lastName,
      first_name: this.firstName,
      address: this.address,
      zipcode: this.zipcode,
      city: this.city,
      country: this.country,
      language: this.language
    };
  }

}
