export interface ICrmClient {
  id: number;
  customer_type?: string;
  gender?: string;
  company_name?: string;
  last_name?: string;
  first_name?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  country?: string;
  is_allowed_without_payment?: boolean;
}

export default class CrmClient {
  public id: number;
  public customerType: string;
  public gender: string;
  public companyName: string;
  public lastName: string;
  public firstName: string;
  public address: string;
  public zipcode: string;
  public city: string;
  public country: string;
  public isAllowedWithoutPayment: boolean;

  constructor(data: ICrmClient) {
    this.id = data.id;
    this.customerType = data.customer_type ?? "";
    this.gender = data.gender ?? "";
    this.companyName = data.company_name ?? "";
    this.lastName = data.last_name ?? "";
    this.firstName = data.first_name ?? "";
    this.address = data.address ?? "";
    this.zipcode = data.zipcode ?? "";
    this.city = data.city ?? "";
    this.country = data.country ?? "";
    this.isAllowedWithoutPayment = data.is_allowed_without_payment ?? false;
  }

  toJson(): Record<string, unknown> {
    return {
      id: this.id,
      customer_type: this.customerType,
      gender: this.gender,
      company_name: this.companyName,
      last_name: this.lastName,
      first_name: this.firstName,
      address: this.address,
      zipcode: this.zipcode,
      city: this.city,
      country: this.country,
      is_allowed_without_payment: this.isAllowedWithoutPayment
    };
  }

}
