
export interface IOptionData {
  id: number;
  type_id: number;
  type_name: string;
  type_icon: string;
  collection_id: number;
  building_id: string;
  unit_type_id: string;
  language: string;
  name: string;
  description: string;
  price: number;
  price_per_unit_of: string;
  price_per_interval_of: string;
  created_at: string;
  permission: string;

  is_locked: boolean;
  is_selected: boolean;
  is_selected_initially: boolean;
}

export default class OptionData {
  private readonly _isFree: boolean;
  private readonly _isMandatory: boolean;

  public id: number;
  public typeId: number;
  public typeName: string;
  public typeIcon: string;
  public collectionId: number;
  public buildingId: string;
  public unitTypeId: string;
  public language: string;
  public name: string;
  public description: string;
  public price: number;
  public pricePerUnitOf: string;
  public pricePerIntervalOf: string;
  public createdAt: string;
  public permission: string;

  public isLocked: boolean;
  public isSelected: boolean;
  public isSelectedInitially: boolean;

  constructor(data: IOptionData) {
    this.id = data.id;
    this.typeId = data.type_id;
    this.typeName = data.type_name;
    this.typeIcon = data.type_icon;
    this.collectionId = data.collection_id;
    this.buildingId = data.building_id;
    this.unitTypeId = data.unit_type_id;
    this.language = data.language;
    this.name = data.name;
    this.description = data.description;
    this.price = data.price;
    this.pricePerUnitOf = data.price_per_unit_of;
    this.pricePerIntervalOf = data.price_per_interval_of;
    this.createdAt = data.created_at;
    this.permission = data.permission;

    this.isLocked = data.is_locked ? data.is_locked : false;
    this.isSelected = data.is_selected ? data.is_selected : false;
    this.isSelectedInitially = data.is_selected_initially ? data.is_selected_initially : false;

    this._isFree = this.price <= 0;
    this._isMandatory = this.permission === "mandatory";
  }

  toJson(): Record<string, unknown> {
    return {
      id: this.id,
      type_id: this.typeId,
      type_name: this.typeName,
      type_icon: this.typeIcon,
      collection_id: this.collectionId,
      building_id: this.buildingId,
      unit_type_id: this.unitTypeId,
      language: this.language,
      name: this.name,
      description: this.description,
      price: this.price,
      price_per_unit_of: this.pricePerUnitOf,
      price_per_interval_of: this.pricePerIntervalOf,
      created_at: this.createdAt,
      permission: this.permission,
      is_locked: this.isLocked,
      is_selected: this.isSelected,
      is_selected_initially: this.isSelectedInitially,
      // Include these if needed
      // is_free: this.isFree(),
      is_mandatory: this.isMandatory()
      // group_id: this.getGroupId()
    };
  }




  public calculatePrice(nights: number, guests: number, maxOneMonth = false): number {
    if (guests < 1) guests = 1;

    let price = this.price;

    if (maxOneMonth && this.pricePerIntervalOf === "day") {
      nights = nights > 30 ? 30 : nights;
      price *= nights;
    }
    else if (this.pricePerIntervalOf === "day") price *= nights;
    else if (this.pricePerIntervalOf === "month") price *= Math.round(nights / 30);

    if (this.pricePerUnitOf === "guest") price *= guests;

    return price;
  }

  public calculatePricePerMonth(nights: number, guests: number): number {
    if (nights > 30) {
      nights = 30;
    }
    return this.calculatePrice(nights, guests);
  }

  public calculatePriceReversed(nights: number, guests: number, price: number): number {
    if (guests < 1) guests = 1;

    if (this.pricePerIntervalOf === "day") price /= nights;
    else if (this.pricePerIntervalOf === "month") price /= Math.round(nights / 30);

    if (this.pricePerUnitOf === "guest") price /= guests;

    return price;
  }

  public isFree(): boolean {
    return this._isFree;
  }

  public isMandatory(): boolean {
    return this._isMandatory;
  }
}
