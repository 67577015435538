import translator from "@/types/services/Translator";
import router from "@/types/services/Router";

export class EsquireClient {
  private baseUrl = process.env.VUE_APP_ESQUIRE_HOST;

  private defaultHeaders = {
    Accept: "application/json"
  } as {
    Accept: string,
    Authorization: string
  };

  public async fetch(createUrl: (baseUrl: string) => URL, options: RequestInit = {}) {
    const headers = {
      ...this.defaultHeaders,
      ...options.headers as Record<string, string>
    };

    const fetchOptions = {
      ...options,
      headers
    };

    const url = createUrl(this.baseUrl);

    return fetch(url.toString(), fetchOptions);
  }
  public async logOutIfForbidden(status: number) {
    if (status === 403) {
      await this.logOut();
    }
  }

  public async logOut(){
    const lang = localStorage.getItem("language") ?? "en";
    localStorage.clear();
    translator.setLanguage(lang);
    await router.routeTo("auth");
  }

  public async fetchUrlFromLocalStorage(key: string): Promise<string> {
    const url = localStorage.getItem(key) ?? "";

    if (!url) {
      await this.logOut();
    }

    return url;
  }



}

export default new EsquireClient();
