import OptionApi from "@/services/esquire/option-api";
import OfferApi from "@/services/esquire/offer-api";

class EsquireService {

  offers() {
    return OfferApi;
  }

  options() {
    return OptionApi;
  }
}

export default new EsquireService();
