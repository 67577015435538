export default {
  breadcrumbs: {
    home: "accueil",
    domotics: "domotique",
    thermostat: "thermostat",
    triggers: "déclencheurs",
    statistics: "statistiques",
    devices: "appareils",
    laundry: "blanchisserie",
    payment: "paiement",
    reservation: "reservation",
    options: "options",
    overview: "aperçu",
    checkin: "checkin",
    done: "statut de paiement",
    "payment-done" : "statut de paiement",
    "reservation-home": "reservations",
    "extend-reservation": "prolonger",
    "passport-scanner": "scanner de passeport",
    "pay-overview": "payer",
    offers: "devis",
    book: "réserver"
  },
  menu: {
    log_out: "Se déconnecter",
    change_language: "Changer la langue"
  },
  auth: {
    welcome: "Bienvenue sur l'application invité Be-Housing",
    admin_login: "Connexion Administrateur",
    admin_login_text: "Pour les secrétaires et le personnel administratif afin de prolonger les contrats des résidents. Cette connexion ne permet pas l'accès au contrôle de la domotique des chambres.",
    guest_login: "Connexion Invité",
    guest_login_text: "Pour les résidents actuels afin de gérer la domotique de leur chambre. Cette connexion ne permet pas la prolongation des contrats."
  },
  login: {
    guest_login: "Connexion Invité",
    reservation_login: "Connexion Réservation",
    login_info: "Veuillez entrer votre code de contrat ci-dessous",
    error_fill_in: "Veuillez remplir votre code de contrat",
    error_length: "Votre code de contrat doit comporter 6 lettres",
    error_letters: "Votre code de contrat ne peut contenir que des lettres et des chiffres",
    error_no_linked_account: "Aucun compte lié trouvé. Veuillez entrer une adresse mail pour créer un compte.",
    email_sent: "Email avec lien de connexion authentifié envoyé avec succès.",
    error_not_active: "Aucun contrat actif trouvé avec ce code.",
    close: "Fermer",
    reservation: {
      login_info: "Veuillez entrer votre identifiant de contrat et votre adresse mail ci-dessous. Vous recevrez un email authentifié pouvant être utilisé pour vous connecter."
    }
  }
  ,
  loading_reservation: "Chargement de la réservation"
}
