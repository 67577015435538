
export type RoomData = {
  flat_type: string;
  room_name: string;
  room_img_path: string;
  facilities: Record<string, unknown>;
  flat_website_url: string;
  city_tax: number;
  amount: number;
  price_per_night: number;
};

export default class Room {
  public flatType: string;
  public name: string;
  public imgPath: string;
  public facilities: Record<string, unknown>;
  public flatWebsiteUrl: string;
  public cityTax: number;
  public amount: number;
  public pricePerNight: number;

  constructor(data: RoomData) {
    this.flatType = data.flat_type as string;
    this.name = data.room_name as string;
    this.imgPath = data.room_img_path as string;
    this.facilities = data.facilities as Record<string, unknown>;
    this.flatWebsiteUrl = data.flat_website_url as string;
    this.cityTax = data.city_tax as number;
    this.amount = data.amount as number;
    this.pricePerNight = data.price_per_night as number;
  }

  toJson(): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (this.flatType !== null) json.flat_type = this.flatType;
    if (this.name !== null) json.room_name = this.name;
    if (this.imgPath !== null) json.room_img_path = this.imgPath;
    if (this.facilities !== null) json.facilities = this.facilities;
    if (this.flatWebsiteUrl !== null) json.flat_website_url = this.flatWebsiteUrl;
    if (this.cityTax !== null) json.city_tax = this.cityTax;
    if (this.amount !== null) json.amount = this.amount;
    if (this.pricePerNight !== null) json.price_per_night = this.pricePerNight;

    return json;
  }

}
