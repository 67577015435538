// src/services/SnackbarService.ts
import SnackbarType from "@/types/enums/snackbar-type";
import {ref} from "vue";

class SnackbarService {
  // Reactive state for the snackbar
  public snackbarVisible = ref(false);
  public snackbarMessage = ref("");
  public snackbarColor = ref(SnackbarType.SUCCESS);

  // Method to show the snackbar
  public show(message: string, type: SnackbarType): void {
    this.snackbarMessage.value = message;
    this.snackbarColor.value = type;
    this.snackbarVisible.value = true;
  }
}

export default new SnackbarService();
