import OfferOption, {OfferOptionData} from "@/types/clients/esquire/data/offer/offer-option";
import _ from "lodash";
import OptionData from "@/types/clients/esquire/data/general/contract-data/option";

export type GeneralInfoData = {
  checkin_date: string;
  checkin_time: string;
  checkout_date: string;
  checkout_time: string;
  checkin_until_time: string;
  options: OfferOptionData[];
  location_icon_path: string;
  clock_icon_path: string;
};

export default class GeneralInfo {
  public checkinDate: string;
  public checkinTime: string;
  public checkoutDate: string;
  public checkoutTime: string;
  public checkinUntilTime: string;
  public offerOptions: OfferOption[];

  constructor(data: GeneralInfoData) {
    this.checkinDate = data.checkin_date;
    this.checkinTime = data.checkin_time;
    this.checkoutDate = data.checkout_date;
    this.checkoutTime = data.checkout_time;
    this.checkinUntilTime = data.checkin_until_time;
    this.offerOptions = _.map(data.options, (option: OfferOptionData) => new OfferOption(option));
  }

  getOptions(): OptionData[] {
    return this.offerOptions.map((offerOption: OfferOption) => offerOption.option);
  }

  toJson(): Record<string, unknown> {
    return {
      checkin_date: this.checkinDate,
      checkin_time: this.checkinTime,
      checkout_date: this.checkoutDate,
      checkout_time: this.checkoutTime,
      checkin_until_time: this.checkinUntilTime,
      options: this.offerOptions
    };
  }
}
