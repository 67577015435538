import esquire from "@/types/clients/esquire";
import PostOfferToReservationPayload
  from "@/types/clients/esquire/payloads/post-offer-to-reservation-payload";
import PostOfferToReservationRequest
  from "@/types/clients/esquire/requests/general/post-offer-to-reservation-request";
import Offer from "@/types/clients/esquire/data/offer/offer";
import PutResponse from "@/types/clients/esquire/responses/put-response";
import OfferResponse from "@/types/enums/offer-response";
import PutOfferRequest from "@/types/clients/esquire/requests/general/put-offer-request";
import PutOfferResponse from "@/types/clients/esquire/responses/put-offer-response";
import GetOffers from "@/types/clients/esquire/requests/general/get-offers";

class OfferApi {

  async bookOffer(offer: Offer): Promise<PutResponse> {
    const payload = new PostOfferToReservationPayload(offer, offer.clientOffer.id);
    const request = new PostOfferToReservationRequest(esquire);
    return await request.send(payload);
  }

  async getOffers(): Promise<Offer[]> {
    const request = new GetOffers(esquire);
    return request.send();
  }

  async replyToOffer(offerResponse: OfferResponse, offerId: number) {
    const request = new PutOfferRequest(esquire);
    return await request.send(new PutOfferResponse(offerResponse, offerId));
  }
}

export default new OfferApi();
