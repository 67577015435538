import dayjs from "dayjs";
import ExpressCheckinInfo, {IExpressCheckinInfoData} from "@/types/clients/esquire/data/general/contract-data/express-checkin-info";
import Guest, {IGuestData} from "@/types/clients/esquire/data/general/contract-data/guest";
import Unit, {IUnitData} from "@/types/clients/esquire/data/general/contract-data/unit";
import UnitType, {IUnitTypeData} from "@/types/clients/esquire/data/general/contract-data/unit-type";
import Option, {IOptionData} from "@/types/clients/esquire/data/general/contract-data/option";
import InvoiceLine, {IInvoiceLineData} from "@/types/clients/esquire/data/general/contract-data/invoice-line";
import Payment, {IPaymentData} from "@/types/clients/esquire/data/general/contract-data/payment";
import InvoiceLineMinified from "@/types/clients/esquire/data/general/contract-data/invoice-line-minified";
import OptionData from "@/types/clients/esquire/data/general/contract-data/option";
import _ from "lodash";
import FlatImage, {FlatImageData} from "@/types/clients/esquire/data/general/flat-image";

export interface IContractData {
  id: string;
  reservation_id: string;
  start_date: string;
  end_date: string;
  unit_id: string;
  unit_type_id: string;
  amount_of_guests: number;
  created_at: string;
  created_by: string;
  key_pickup_at?: string;
  key_pickup_by?: string;
  key_back_at?: string;
  key_back_by?: string;
  outgoing_state_at?: string;
  cancelled_at?: string;
  cancelled_because?: string;
  allow_without_payment: boolean;

  express_checkin_info?: IExpressCheckinInfoData;
  guest: IGuestData;
  invoice_lines: IInvoiceLineData[];
  flat_image: FlatImageData;
  options: IOptionData[];
  payments: IPaymentData[];
  unit?: IUnitData;
  unit_type: IUnitTypeData;

}


export default class Contract {
  public id: string;
  public reservationId: null|string;
  public startDate: string;
  public endDate: string;
  public unitId: string;
  public unitTypeId: string;
  public amountOfGuests: number;
  public createdAt: string;
  public createdBy: string;
  public keyPickupAt: null|string;
  public keyPickupBy: null|string;
  public keyBackAt: null|string;
  public keyBackBy: null|string;
  public outgoingStateAt: null|string;
  public cancelledAt: null|string;
  public cancelledBecause: null|string;
  public allowWithoutPayment: boolean;

  public expressCheckinInfo: null|ExpressCheckinInfo;
  public guest: Guest;
  public unit: Unit|null;
  public unitType: UnitType;
  public options: Record<number, Option>;
  public payments: Payment[];
  public invoiceLines: InvoiceLine[];
  public flatImage: FlatImage|null;

  private readonly _accruedDebtToday: number;
  private readonly _accruedDebtBeforeCheckin: number;
  private readonly _accruedDebt: number;
  private readonly _paid: number;

  private readonly _nights: number;
  private readonly _optionNights: number;

  constructor(data: IContractData) {
    this.id = data.id;
    this.reservationId = data.reservation_id ?? null;
    this.startDate = data.start_date;
    this.endDate = data.end_date;
    this.unitId = data.unit_id;
    this.unitTypeId = data.unit_type_id;
    this.amountOfGuests = data.amount_of_guests;
    this.createdAt = data.created_at;
    this.createdBy = data.created_by;
    this.keyPickupAt = data.key_pickup_at ?? null;
    this.keyPickupBy = data.key_pickup_by ?? null;
    this.keyBackAt = data.key_back_at ?? null;
    this.keyBackBy = data.key_back_by ?? null;
    this.outgoingStateAt = data.outgoing_state_at ?? null;
    this.cancelledAt = data.cancelled_at ?? null;
    this.cancelledBecause = data.cancelled_because ?? null;
    this.allowWithoutPayment = data.allow_without_payment ?? false;

    this.expressCheckinInfo =  data.express_checkin_info ? new ExpressCheckinInfo(data.express_checkin_info) : null;
    this.guest = new Guest(data.guest);
    this.unit = data.unit ? new Unit(data.unit) : null;
    this.flatImage = data.flat_image ? new FlatImage(data.flat_image) : null;
    this.unitType = new UnitType(data.unit_type);

    this.options = _
      .chain(data.options)
      .filter((data: IOptionData) => data.type_id !== 67)
      .map((data: IOptionData) => new Option(data))
      .keyBy((option: Option) => option.typeId)
      .value();

    this.payments = _.map(data.payments, (data: IPaymentData) => new Payment(data));

    this.invoiceLines = _.map(data.invoice_lines, (data: IInvoiceLineData) => new InvoiceLine(data));

    for (const invoiceLine of this.invoiceLines) {
      const optionTypeId = invoiceLine.optionTypeId;

      if (!optionTypeId) continue;

      const option = this.options[optionTypeId];

      if (!option) continue;

      option.isLocked = true;
      option.isSelected = true;
      option.isSelectedInitially = true;
    }

    //this.synchronizeOptionPricesWithExistingInvoiceLines();

    this._accruedDebt = this.calculateAccruedDebt();
    this._accruedDebtToday = this.calculateAccruedDebtToday();
    this._accruedDebtBeforeCheckin = this.calculateAccruedDebtBeforeCheckin();
    this._paid = this.calculatePaid();

    this._nights = this.calculateNights();
    this._optionNights = this.calculateOptionNights();
  }

  toJson(): Record<string, unknown> {
    const json: Record<string, unknown> = {};

    json.id = this.id;
    json.reservation_id = this.reservationId;
    json.start_date = this.startDate;
    json.end_date = this.endDate;
    json.unit_id = this.unitId;
    json.unit_type_id = this.unitTypeId;
    json.amount_of_guests = this.amountOfGuests;
    json.created_at = this.createdAt;
    json.created_by = this.createdBy;
    json.key_pickup_at = this.keyPickupAt;
    json.key_pickup_by = this.keyPickupBy;
    json.key_back_at = this.keyBackAt;
    json.key_back_by = this.keyBackBy;
    json.outgoing_state_at = this.outgoingStateAt;
    json.cancelled_at = this.cancelledAt;
    json.cancelled_because = this.cancelledBecause;

    json.express_checkin_info = this.expressCheckinInfo ? this.expressCheckinInfo.toJson() : null;
    json.guest = this.guest.toJson();
    json.unit = this.unit ? this.unit.toJson() : null;
    json.unit_type = this.unitType.toJson();

    json.options = Object.values(this.options).map((option: OptionData) => option.toJson());
    json.payments = this.payments.map(payment => payment.toJson());
    json.invoice_lines = this.invoiceLines.map(invoiceLine => invoiceLine.toJson());

    json.accrued_debt = this.accruedDebt;
    json.nights = this.nights;
    json.option_nights = this.optionNights;
    json.paid = this.paid;

    return json;
  }

  get arrivesToday(): boolean {
    return dayjs(this.startDate).isSame(dayjs(), "day")
  }

  get formattedArrivalDate(): string {
    return dayjs(this.startDate).format("DD/MM/YYYY");
  }
  get formattedDepartureDate(): string {
    return dayjs(this.endDate).format("DD/MM/YYYY");
  }

  get accruedDebt(): number {
    return this._accruedDebt;
  }
  get accruedDebtToday(): number {
    return this._accruedDebtToday;
  }
  get accruedDebtBeforeCheckin(): number {
    return this._accruedDebtBeforeCheckin;
  }

  get nights(): number {
    return this._nights;
  }


  get beds(): number {
    if (!this.unit) return 0;
    return this.unit.singleBedCount + this.unit.doubleBedCount;
  }

  get optionNights(): number {
    return this._optionNights;
  }

  get paid(): number {
    return this._paid;
  }

  public getCombinedAddedAndRemovedOptions(): Option[] {
    const addedOptions = this.getAddedOptions().map(option => ({
      ...option,
      adjustedPrice: option.calculatePricePerMonth(this.nights, this.amountOfGuests)
    }));
    const removedOptions = this.getRemovedOptions().map(option => ({
      ...option,
      adjustedPrice: -option.calculatePricePerMonth(this.nights, this.amountOfGuests)
    }));

    return _.merge(addedOptions, removedOptions)
  }

  public calculateToPay(): number {
    const toPay = this.accruedDebt - this.paid + this.getOptionPriceTotal();
    return toPay > 0 ? toPay : 0;

  }

  public calculateToPayToday(): number {
    const toPayToday = this.accruedDebtToday - this.paid + this.getOptionPricePerMonth();
    return toPayToday > 0 ? toPayToday : 0;

  }

  public getAddedOptions(): Option[] {
    return _.filter(this.options, (o: Option) => !o.isSelectedInitially && o.isSelected);
  }

  public getNonMandatoryOptions(): Option[] {
    return _.filter(this.options, (o: Option) => !o.isMandatory());
  }

  public getInvoiceLinesToPayBeforeCheckin(): InvoiceLine[] {
    return _.filter(this.invoiceLines, (i: InvoiceLine) => dayjs(this.startDate).isSame(i.payableAt) || dayjs(this.startDate).isAfter(i.payableAt) );
  }

  public getInvoiceLinesToPayToday(): InvoiceLine[] {
    return _.filter(this.invoiceLines, (i: InvoiceLine) => dayjs(i.payableAt).isBefore(dayjs()));
  }

  public getOptionPriceTotal(): number {
    const positivePrice = _.chain(this.getAddedOptions())
      .map((o: Option) => o.calculatePrice(this.optionNights, this.amountOfGuests))
      .sum()
      .value();

    const negativePrice = _.chain(this.getRemovedOptions())
      .map((o: Option) => o.calculatePrice(this.optionNights, this.amountOfGuests))
      .sum()
      .value();

    return positivePrice - negativePrice;
  }
  public getOptionPricePerMonth(): number {
    const positivePrice = _.chain(this.getAddedOptions())
      .map((o: Option) => o.calculatePricePerMonth(this.optionNights, this.amountOfGuests))
      .sum()
      .value();

    const negativePrice = _.chain(this.getRemovedOptions())
      .map((o: Option) => o.calculatePricePerMonth(this.optionNights, this.amountOfGuests))
      .sum()
      .value();

    return positivePrice - negativePrice;
  }
  public getOptionPricePerAmountOfNights(nights: number): number {
    const positivePrice = _.chain(this.getAddedOptions())
      .map((o: Option) => o.calculatePricePerMonth(nights, this.amountOfGuests))
      .sum()
      .value();

    const negativePrice = _.chain(this.getRemovedOptions())
      .map((o: Option) => o.calculatePricePerMonth(nights, this.amountOfGuests))
      .sum()
      .value();

    return positivePrice - negativePrice;
  }

  public getRemovedOptions(): Option[] {
    return _.filter(this.options, (o: Option) => o.isSelectedInitially && !o.isSelected);
  }

  private calculateAccruedDebt(): number {
    return _.chain(this.invoiceLines)
      .sumBy((i: InvoiceLine) => i.price)
      .value();
  }

  private calculateAccruedDebtToday(): number {
    return _.chain(this.invoiceLines)
      .filter((i: InvoiceLine) => dayjs().isSame(i.payableAt, "day") || dayjs().isAfter(i.payableAt, "day"))
      .sumBy((i: InvoiceLine) => i.price)
      .value();
  }

  private calculateAccruedDebtBeforeCheckin(): number {
    return _.chain(this.invoiceLines)
      .filter((i: InvoiceLine) => dayjs(this.startDate).isSame(dayjs(i.payableAt), "day") || dayjs(this.startDate).isAfter(dayjs(i.payableAt), "day"))
      .sumBy((i: InvoiceLine) => i.price)
      .value();
  }

  private calculateNights(): number {
    return dayjs(this.endDate).diff(this.startDate, "days");
  }

  private calculateOptionNights(): number {
    const startDate = dayjs(this.startDate);

    const today = dayjs().startOf("day");

    const optionStartDate = today.isAfter(startDate) ? today : startDate;

    return dayjs(this.endDate).diff(optionStartDate, "days");
  }

  private calculatePaid(): number {
    return _.sumBy(this.payments, (p: Payment) => p.amount);
  }

  private synchronizeOptionPricesWithExistingInvoiceLines(): void {
    const invoiceLinesByOptionTypeId = _.chain(this.invoiceLines)
      .filter((il: InvoiceLine) => !!il.optionTypeId)
      .orderBy([(il: InvoiceLine) => il.startDate], ["asc"])
      .groupBy((il: InvoiceLine) => il.optionTypeId as number)
      .mapValues((ils: InvoiceLine[]) => _.map(ils, (il: InvoiceLine) => new InvoiceLineMinified(
        il.calculateDays(),
        il.price,
        il.guestCount
      )))
      .mapValues((ilrs: InvoiceLineMinified[]) => _.reduce(ilrs, (result: InvoiceLineMinified, ilr: InvoiceLineMinified) => {
        if (!result) return ilr;

        result.days += ilr.days;
        result.price += ilr.price;
        result.guests = Math.round((result.guests + ilr.guests) / 2);

        return result;
      }))
      .value();

    for (const typeId in this.options) {
      const invoiceLineMinified = invoiceLinesByOptionTypeId[typeId];

      if (!invoiceLineMinified) continue;

      const option = this.options[typeId];

      this.options[typeId].price = option.calculatePriceReversed(
        invoiceLineMinified.days, invoiceLineMinified.guests, invoiceLineMinified.price
      );

    }
  }
}
