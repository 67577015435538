import JsonResource from "@/types/interfaces/JsonResource";
import OfferResponse from "@/types/enums/offer-response";
import Offer from "@/types/clients/esquire/data/offer/offer";

export default class PostOfferToReservationPayload implements JsonResource {
  public offer: Offer;
  public offerId: number;

  constructor(offer: Offer, offerId: number) {
    this.offer = offer;
    this.offerId = offerId;
  }

  toJsonObject(): Record<string, unknown> {
    return {
      offer: this.offer.toJson(),
      offer_id: this.offerId
    };
  }

  toJsonString(): string {
    return JSON.stringify(this.toJsonObject());
  }
}
