import {EsquireClient} from "@/types/clients/esquire";
import PutResponse from "@/types/clients/esquire/responses/put-response";
import PostOfferToReservationPayload
  from "@/types/clients/esquire/payloads/post-offer-to-reservation-payload";

export default class PostOfferToReservationRequest {
  constructor(private client: EsquireClient) {
  }

  public async send(offerData: PostOfferToReservationPayload): Promise<PutResponse> {
    const url = await this.client.fetchUrlFromLocalStorage("post-offer-to-reservation");

    const response = await this.client.fetch(
      () => new URL(url), {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(offerData.toJsonObject())
      });

    await this.client.logOutIfForbidden(response.status);
    const responseData = await response.json();

    if (!response.ok) {
      return new PutResponse(responseData.message, response.status, responseData.identifier);
    }

    return new PutResponse(responseData.message, response.status, responseData.identifier);

  }
}
