import GetFlatOptions from "@/types/clients/esquire/requests/general/get-flat-options";
import OptionData from "@/types/clients/esquire/data/general/contract-data/option";
import esquire from "@/types/clients/esquire";

class OptionApi {

  async getFlatOptions(flatTypes: string[]): Promise<OptionData[]> {
    const request = new GetFlatOptions(esquire);
    return await request.send(flatTypes);

  }
}

export default new OptionApi();
