export default {
  breadcrumbs: {
    home: "home",
    domotics: "domotica",
    thermostat: "thermostaat",
    triggers: "triggers",
    statistics: "statistieken",
    devices: "apparaten",
    laundry: "de was",
    payment: "betaling",
    reservation: "reservatie",
    options: "opties",
    overview: "overzicht",
    checkin: "check-in",
    done: "betalingsstatus",
    "payment-done": "betalingsstatus",
    "reservation-home": "reserveringen",
    "extend-reservation": "verlengen",
    "passport-scanner": "paspoortscanner",
    "pay-overview": "betalingsoverzicht",
    offers: "offertes",
    book: "boeken"
  },
  menu: {
    log_out: "Uitloggen",
    change_language: "Taal wijzigen"
  },
  auth: {
    welcome: "Welkom in de Be-Housing Gastenapp",
    admin_login: "Beheerderslogin",
    admin_login_text: "Voor administratief personeel om residentiële contracten te verlengen. Deze login geeft geen toegang tot de bediening van de domotica van de kamers.",
    guest_login: "Login gasten",
    guest_login_text: "Voor huidige bewoners om de domotica van hun kamer te beheren. Deze login staat contractverlengingen niet toe."
  },
  login: {
    guest_login: "Login gasten",
    reservation_login: "Beheerderslogin",
    login_info: "Gelieve hieronder uw contractcode in te voeren",
    error_fill_in: "Vul uw contractcode in",
    error_length: "Uw contractcode moet 6 letters lang zijn",
    error_letters: "Uw contractcode mag alleen letters en cijfers bevatten",
    error_no_linked_account: "Geen gekoppeld account gevonden. Voer een e-mailadres in om een account aan te maken.",
    email_sent: "E-mail met geverifieerde inloglink succesvol verzonden.",
    error_not_active: "Geen actief contract gevonden met deze code.",
    close: "Sluiten",
    reservation: {
      "login_info": "Voer hieronder uw contract-ID en e-mailadres in. U ontvangt een geverifieerde e-mail die kan worden gebruikt om in te loggen."
    }
  }
  ,
  loading_reservation: "Reservatie laden"
}
