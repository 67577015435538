import OverviewFlat, {OverviewFlatData} from "@/types/clients/esquire/data/offer/overview-flat";

export type StayData = {
  flats: OverviewFlatData[];
  vat: number;
  vat_percentage: string;
  total: number;
  total_amount_of_persons: number;
  one_time_options_descriptions: string[] | null;
  one_time_options_prices: number[] | null;
  total_once_time_price: number | null;
  amount_of_flats: number | null;
  after_first_month: string | null;
  vat_monthly: number | null;
  total_monthly: number | null;
  total_price_rooms_combined_per_month: number | null;
  one_month_later: string | null;
  total_monthly_no_vat: number | null;
};

export default class Stay {
  public flats: OverviewFlat[];
  public vat: number;
  public vatPercentage: string;
  public total: number;
  public totalAmountOfPersons: number;
  public oneTimeOptionsDescriptions: string[] | null;
  public oneTimeOptionsPrices: number[] | null;
  public totalOnceTimePrice: number | null;
  public amountOfFlats: number | null;
  public afterFirstMonth: string | null;
  public vatMonthly: number | null;
  public totalMonthly: number;
  public totalPriceRoomsCombinedPerMonth: number;
  public oneMonthLater: string | null;
  public totalMonthlyNoVat: number | null;

  constructor(data: StayData) {
    this.flats = data.flats.map(flat => new OverviewFlat(flat));
    this.vat = data.vat;
    this.vatPercentage = data.vat_percentage;
    this.total = data.total;
    this.totalAmountOfPersons = data.total_amount_of_persons;
    this.oneTimeOptionsDescriptions = data.one_time_options_descriptions;
    this.oneTimeOptionsPrices = data.one_time_options_prices;
    this.totalOnceTimePrice = data.total_once_time_price;
    this.amountOfFlats = data.amount_of_flats;
    this.afterFirstMonth = data.after_first_month;
    this.vatMonthly = data.vat_monthly;
    this.totalMonthly = data.total_monthly ?? 0;
    this.totalPriceRoomsCombinedPerMonth = data.total_price_rooms_combined_per_month ?? 0;
    this.oneMonthLater = data.one_month_later;
    this.totalMonthlyNoVat = data.total_monthly_no_vat;
  }

  toJson(): Record<string, unknown> {
    return {
      flats: this.flats.map(flat => flat.toJson()),
      vat: this.vat,
      vat_percentage: this.vatPercentage,
      total: this.total,
      total_amount_of_persons: this.totalAmountOfPersons,
      one_time_options_descriptions: this.oneTimeOptionsDescriptions,
      one_time_options_prices: this.oneTimeOptionsPrices,
      total_once_time_price: this.totalOnceTimePrice,
      amount_of_flats: this.amountOfFlats,
      after_first_month: this.afterFirstMonth,
      vat_monthly: this.vatMonthly,
      total_monthly: this.totalMonthly,
      total_price_rooms_combined_per_month: this.totalPriceRoomsCombinedPerMonth,
      one_month_later: this.oneMonthLater,
      total_monthly_no_vat: this.totalMonthlyNoVat
    };
  }

  get isValid(): boolean {
    return this.flats.every(flat => flat.isValid);
  }


}
