<template>
  <v-app>
    <app-toolbar />
    <v-main>
      <router-view />
    </v-main>

    <v-snackbar v-model="snackbarVisible" :color="snackbarColor" top right>
      {{ snackbarMessage }}
    </v-snackbar>
  </v-app>
</template>

<script lang="ts" setup>
import GlobalService from "@/services/global-service";
import AppToolbar from "@/components/AppToolbar.vue";

const snackbarVisible = GlobalService.snackbar().snackbarVisible;
const snackbarMessage = GlobalService.snackbar().snackbarMessage;
const snackbarColor = GlobalService.snackbar().snackbarColor;

</script>

<style lang="scss">
@import "@/assets/scss/main.scss";
</style>
