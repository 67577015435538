export default {
  breadcrumbs: {
    home: "home",
    domotics: "domotics",
    thermostat: "thermostat",
    triggers: "triggers",
    statistics: "statistics",
    devices: "devices",
    laundry: "laundry",
    payment: "payment",
    reservation: "reservation",
    checkin: "checkin",
    options: "options",
    overview: "overview",
    done: "payment status",
    "payment-done": "payment status",
    "reservation-home": "reservations",
    "extend-reservation": "extend",
    "passport-scanner": "passport scanner",
    "pay-overview": "pay overview",
    offers: "offers",
    book: "book"
  },
  menu: {
    log_out: "Log out",
    change_language: "Change language"
  },
  auth: {
    welcome: "Welcome to the Be-Housing Guest App",
    admin_login: "Admin Login",
    admin_login_text: "For secretaries and administrative personnel to extend resident contracts. This login does not grant access to room domotics control.",
    guest_login: "Guest Login",
    guest_login_text: "For current residents to manage their room's domotics. This login does not permit contract extensions."
  },
  login: {
    guest_login: "Guest Login",
    reservation_login: "Reservation Login",
    login_info: "Please enter your contract code below",
    error_fill_in: "Fill in your contract code",
    error_length: "Your contract code should be 6 letters long",
    error_letters: "Your contract code can only contain letters and numbers",
    error_no_linked_account: "No linked account found. Please enter a mail address to create an account.",
    email_sent: "Email with authenticated login link successfully sent.",
    error_not_active: "No active contract could be found with this code.",
    close: "Close",
    reservation: {
      login_info: "Please enter your contract ID & mail address below. You will receive an authenticated email that can be used to log in"

    }
  },
  loading_reservation: "Loading reservation"
}
