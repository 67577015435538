import OptionData, {IOptionData} from "@/types/clients/esquire/data/general/contract-data/option";

export type OfferOptionData = {
  unity_factor: string;
  total_price: number;
  unity_price: number;
  name: string;
  description: string;
  option: IOptionData;
};

export default class OfferOption {
  public unityFactor: string;
  public totalPrice: number;
  public unityPrice: number;
  public name: string;
  public description: string;
  public option: OptionData;

  constructor(data: OfferOptionData) {
    this.unityFactor = data.unity_factor;
    this.totalPrice = data.total_price;
    this.unityPrice = data.unity_price;
    this.name = data.name;
    this.description = data.description;
    this.option = new OptionData(data.option);
  }

  toJson(): Record<string, unknown> {
    return {
      unity_factor: this.unityFactor,
      total_price: this.totalPrice,
      unity_price: this.unityPrice,
      name: this.name,
      description: this.description,
      option: this.option.toJson()
    };
  }
}
