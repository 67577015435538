export default class PutResponse {
  public message: string;
  public code: number;
  public identifier: string;

  constructor(message: string, code: number, identifier: string) {
    this.message = message;
    this.code = code;
    this.identifier = identifier;
  }

  public isSuccess(): boolean {
    return this.code >= 200 && this.code < 300;
  }

  public formatResponse(): string {
    return `Code: ${this.code}, Identifier: ${this.identifier}, Message: ${this.message}`;
  }
}
