import Building, {IBuilding} from "@/types/clients/esquire/data/general/building";

export type CityInfoData = {
  offer_number: number;
  building: IBuilding;
  city_img_path: string;
  behousing_logo_path: string;
  from: string;
  until: string;
  info: string;
};

export default class CityInfo {
  public offerNumber: number;
  public building: Building;
  public cityImgPath: string;
  public beHousingLogoPath: string;
  public from: string;
  public until: string;
  public info: string;

  constructor(data: CityInfoData) {
    this.offerNumber = data.offer_number as number;
    this.building = new Building(data.building);
    this.cityImgPath = data.city_img_path as string;
    this.beHousingLogoPath = data.behousing_logo_path as string;
    this.from = data.from as string;
    this.until = data.until as string;
    this.info = data.info as string;
  }

  toJson(): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (this.offerNumber !== null) json.offer_number = this.offerNumber;
    if (this.building !== null) json.building = this.building.toJson();
    if (this.cityImgPath !== null) json.city_img_path = this.cityImgPath;
    if (this.beHousingLogoPath !== null) json.behousing_logo_path = this.beHousingLogoPath;
    if (this.from !== null) json.from = this.from;
    if (this.until !== null) json.until = this.until;
    if (this.info !== null) json.info = this.info;

    return json;
  }

}
