import Stay, {StayData} from "@/types/clients/esquire/data/offer/stay";

export type OverviewData = {
  client_name: string;
  client_offer_id: string;
  client_address: string;
  client_city: string;
  checkin_date: string;
  checkout_date: string;
  nights: number;
  valid_until: string;
  stay: StayData;
  remarks: string;
  is_refundable: boolean;
};

export default class Overview {
  public clientName: string;
  public clientOfferId: string;
  public clientAddress: string;
  public clientCity: string;
  public checkinDate: string;
  public checkoutDate: string;
  public nights: number;
  public validUntil: string;
  public stay: Stay;
  public remarks: string;
  public isRefundable: boolean;

  constructor(data: OverviewData) {
    this.clientName = data.client_name as string;
    this.clientOfferId = data.client_offer_id as string;
    this.clientAddress = data.client_address as string;
    this.clientCity = data.client_city as string;
    this.checkinDate = data.checkin_date as string;
    this.checkoutDate = data.checkout_date as string;
    this.nights = data.nights as number;
    this.validUntil = data.valid_until as string;
    this.stay = new Stay(data.stay);
    this.remarks = data.remarks as string;
    this.isRefundable = data.is_refundable as boolean;
  }

  toJson(): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (this.clientName !== null) json.client_name = this.clientName;
    if (this.clientOfferId !== null) json.client_offer_id = this.clientOfferId;
    if (this.clientAddress !== null) json.client_address = this.clientAddress;
    if (this.clientCity !== null) json.client_city = this.clientCity;
    if (this.checkinDate !== null) json.checkin_date = this.checkinDate;
    if (this.checkoutDate !== null) json.checkout_date = this.checkoutDate;
    if (this.nights !== null) json.nights = this.nights;
    if (this.validUntil !== null) json.valid_until = this.validUntil;
    if (this.stay !== null) json.stay = this.stay.toJson();
    if (this.remarks !== null) json.remarks = this.remarks;
    if (this.isRefundable !== null) json.is_refundable = this.isRefundable;

    return json;
  }

}
