import OfferOption, {OfferOptionData} from "@/types/clients/esquire/data/offer/offer-option";
import Option from "@/types/clients/esquire/data/general/contract-data/option";
import _ from "lodash";
import CrmRepresentative from "@/types/clients/esquire/data/general/crm-representative";

export type SelectedFlatData = {
  price_apartment: number;
  options: OfferOptionData[];
  amount_of_persons: string;
  price: number;
};

export default class SelectedFlat {
  public priceApartment: number;
  public offerOptions: OfferOption[];
  public amountOfPersons: string;
  public price: number;

  public offerGuestInfo: CrmRepresentative | null = null;
  public clientConfirmedOptions = false;

  constructor(data: SelectedFlatData) {
    this.priceApartment = data.price_apartment;
    this.offerOptions = data.options.map(option => new OfferOption(option));
    this.amountOfPersons = data.amount_of_persons;
    this.price = data.price;
  }

  toJson(): Record<string, unknown> {
    return {
      price_apartment: this.priceApartment,
      options: this.offerOptions.map(option => option.toJson()),
      amount_of_persons: this.amountOfPersons,
      price: this.price,
      offer_guest_info: this.offerGuestInfo ? this.offerGuestInfo.toJson() : null
    };
  }

  public getNonMandatoryOptions(): Option[] {
    return _.filter(this.getOptions(), (option: Option) => !option.isMandatory());
  }

  public getOptions(): Option[] {
    return this.offerOptions.map(offerOption => offerOption.option);
  }

  get guestInfoIsValid(): boolean {
    if (!this.offerGuestInfo) return false;

    const {gender , email, lastName, firstName, address, phoneNumber} = this.offerGuestInfo;

    return Boolean(
      gender &&
      email &&
      lastName &&
      firstName &&
      address &&
      phoneNumber
    );
  }

  get isValid(): boolean {
    return this.guestInfoIsValid && this.clientConfirmedOptions;
  }

}
