import JsonResource from "@/types/interfaces/JsonResource";
import OfferResponse from "@/types/enums/offer-response";

export default class PutOfferResponse implements JsonResource {
  public response: OfferResponse;
  public offerId: number;

  constructor(response: OfferResponse, offerId: number) {
    this.response = response;
    this.offerId = offerId;
  }

  toJsonObject(): Record<string, unknown> {
    return {
      response: this.response,
      offer_id: this.offerId
    };
  }

  toJsonString(): string {
    return JSON.stringify(this.toJsonObject());
  }
}
