import SelectedFlat, {SelectedFlatData} from "@/types/clients/esquire/data/offer/selected-flat";
import OfferOption from "@/types/clients/esquire/data/offer/offer-option";

export type OverviewFlatData = {
  price: number;
  description: string;
  flat_type: string;
  price_per_night: string;
  selected_flats: SelectedFlatData[];
};

export default class OverviewFlat {
  public price: number;
  public description: string;
  public flatType: string;
  public pricePerNight: string;
  public selectedFlats: SelectedFlat[];

  constructor(data: OverviewFlatData) {
    this.price = data.price;
    this.description = data.description;
    this.flatType = data.flat_type;
    this.pricePerNight = data.price_per_night;
    this.selectedFlats = Array.isArray(data.selected_flats)
      ? data.selected_flats.map(flat => new SelectedFlat(flat))
      : [];
  }

  toJson(): Record<string, unknown> {
    return {
      price: this.price,
      description: this.description,
      flat_type: this.flatType,
      price_per_night: this.pricePerNight,
      selected_flats: this.selectedFlats.map(flat => flat.toJson())
    };
  }

  public priceIncludingOptions(): number {
    return this.selectedFlats.reduce((total, flat) => total + flat.price, 0);
  }

  public totalSumOfSelectedFlatsIncludingOptions(): number{
    return this.selectedFlats.reduce((total, selectedFlat) => {
      const basePrice = selectedFlat.priceApartment;
      const optionPrices = selectedFlat.offerOptions.reduce(
        (optionTotal, option) => optionTotal + option.totalPrice,
        0
      );
      return total + basePrice + optionPrices;
    }, 0);
  }

  public getSelectedFlatDetails(): { description: string; price: number; }[] {
    return this.selectedFlats.flatMap(selectedFlat => {
      const flatDetails = {
        description: `Apartment (${selectedFlat.amountOfPersons} persons)`,
        price: selectedFlat.priceApartment
      };

      const options = selectedFlat.offerOptions.map((option: OfferOption) => ({
        description: option.name,
        price: option.totalPrice
      }));

      return [flatDetails, ...options];
    });
  }

  get isValid(): boolean {
    return this.selectedFlats.every(flat => flat.isValid);
  }
}
