import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import GlobalService from "@/services/global-service";
import AppToolbar from "@/components/AppToolbar.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const snackbarVisible = GlobalService.snackbar().snackbarVisible;
const snackbarMessage = GlobalService.snackbar().snackbarMessage;
const snackbarColor = GlobalService.snackbar().snackbarColor;


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(AppToolbar),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      _createVNode(_component_v_snackbar, {
        modelValue: _unref(snackbarVisible),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(snackbarVisible) ? (snackbarVisible).value = $event : null)),
        color: _unref(snackbarColor),
        top: "",
        right: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(snackbarMessage)), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "color"])
    ]),
    _: 1
  }))
}
}

})