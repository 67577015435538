import SnackbarService from "@/services/snackbar-service";
import RouterService from "@/services/router-service";
import Translator from "@/types/services/Translator";
import EsquireService from "@/services/esquire-service";

class GlobalService {

  snackbar() {
    return SnackbarService;
  }

  router() {
    return RouterService;
  }
  translator() {
    return Translator;
  }

  esquire() {
    return EsquireService;
  }
}

export default new GlobalService();
