import CrmClient, {ICrmClient} from "@/types/clients/esquire/data/general/crm-client";
import CrmRepresentative, {
  ICrmRepresentative
} from "@/types/clients/esquire/data/general/crm-representative";

export type ClientOfferData = {
  id: number;
  representative_id: number | null;
  client_id: number | null;
  start_date: string | null;
  end_date: string | null;
  expiration_date: string | null;
  key: string | null;
  mail_sent: string | null;
  accepted_at: string | null;
  rejected_at: string | null;
  booked_at: string | null;
  created_at: string;
  language: string | null;
  crm_client: ICrmClient | null;
  crm_representative: ICrmRepresentative | null;
};

export default class ClientOffer {
  public id: number;
  public representativeId: number | null;
  public clientId: number | null;
  public startDate: string | null;
  public endDate: string | null;
  public expirationDate: string | null;
  public key: string | null;
  public mailSent: string | null;
  public acceptedAt: string | null;
  public rejectedAt: string | null;
  public bookedAt: string | null;
  public createdAt: string;
  public language: string | null;
  public crmClient: CrmClient | null;
  public crmRepresentative: CrmRepresentative | null;

  constructor(data: ClientOfferData) {
    this.id = data.id;
    this.representativeId = data.representative_id;
    this.clientId = data.client_id;
    this.startDate = data.start_date;
    this.endDate = data.end_date;
    this.expirationDate = data.expiration_date;
    this.key = data.key;
    this.mailSent = data.mail_sent;
    this.acceptedAt = data.accepted_at;
    this.rejectedAt = data.rejected_at;
    this.bookedAt = data.booked_at;
    this.createdAt = data.created_at;
    this.language = data.language;
    this.crmClient = data.crm_client ? new CrmClient(data.crm_client as ICrmClient) : null;
    this.crmRepresentative = data.crm_representative ? new CrmRepresentative(data.crm_representative as ICrmRepresentative) : null;
  }

  toJson(): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (this.id !== null) json.id = this.id;
    if (this.representativeId !== null) json.representative_id = this.representativeId;
    if (this.clientId !== null) json.client_id = this.clientId;
    if (this.startDate !== null) json.start_date = this.startDate;
    if (this.endDate !== null) json.end_date = this.endDate;
    if (this.expirationDate !== null) json.expiration_date = this.expirationDate;
    if (this.key !== null) json.key = this.key;
    if (this.mailSent !== null) json.mail_sent = this.mailSent;
    if (this.acceptedAt !== null) json.accepted_at = this.acceptedAt;
    if (this.rejectedAt !== null) json.rejected_at = this.rejectedAt;
    if (this.bookedAt !== null) json.booked_at = this.bookedAt;
    if (this.createdAt !== null) json.created_at = this.createdAt;
    if (this.language !== null) json.language = this.language;
    if (this.crmClient !== null) json.crm_client = this.crmClient.toJson();
    if (this.crmRepresentative !== null) json.crm_representative = this.crmRepresentative.toJson();

    return json;
  }
}
